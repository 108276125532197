import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { Modal, Button, Image, Row, Col } from "react-bootstrap";

import { networkParams } from "../networks";
import { providers } from "../providers";
import { getShortAddressStr } from "./../utils";
import mm from "./../assets/img/mm.png";
import wc from "./../assets/img/wc.png";
import { TbWallet } from "react-icons/tb";

const WalletConnect = ({ className = "", variant = "" }, props) => {
  const [show, setShow] = useState(false);
  const { activate, deactivate, account, active, chainId, library } =
    useWeb3React();
  const selectedChainId = Number(process.env.REACT_APP_CHAIN_ID);

  const handleWalletConnect = (event) => {
    setShow(true);
  };

  const switchNetwork = async (chainId) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(chainId) }],
      });
    } catch (err) {
      if (err.code === 4902) {
        const _network = networkParams[chainId] || {};

        if (Object.keys.length) {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [_network],
          });
        }
      }
    }
  };

  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  useEffect(() => {
    const lastProvider = localStorage.getItem("provider");

    if (lastProvider) {
      activate(providers[lastProvider]);
    }

    if (chainId !== selectedChainId) {
      switchNetwork(selectedChainId);
    }
  }, []);

  return (
    <>
      {active ? (
        <>
          <button
            variant={variant}
            onClick={() => {
              deactivate();
              localStorage.removeItem("reloadCount");
              setProvider("");
            }}
            className={className}
          >
            <TbWallet size={20} className="me-1" />
            {getShortAddressStr(account)}
          </button>
        </>
      ) : (
        <>
          <button
            variant={variant}
            onClick={handleWalletConnect}
            className={className}
          >
            <TbWallet size={20} className="me-1" />
            Connect
          </button>
        </>
      )}

      <Modal show={show} onHide={setShow}>
        <Modal.Body>
          <Row>
            <Col key={mm} className="text-center p-2" sm={3} md={6}>
              <Button
                className="border-0"
                variant="transparent"
                onClick={() => {
                  activate(providers["Injected"]);

                  setProvider("Injected");

                  setShow(false);
                }}
              >
                <Image fluid src={mm} height={100} width={100} />
                <br />
                <span>Metamask</span>
              </Button>
            </Col>

            <Col key="wc" className="text-center p-2" sm={3} md={6}>
              <Button
                className="border-0"
                variant="transparent"
                onClick={() => {
                  activate(providers["WalletConnect"]);
                  
                  setProvider("WalletConnect");
                  
                  setShow(false);
                }}
              >
                <Image fluid src={wc} height={100} width={100} />
                <br />
                <span>Wallet Connect</span>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletConnect;
