import { Button, ButtonGroup } from "react-bootstrap";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(zoomPlugin);

let Chartx;
let fdatax;
let currentchart = "price";
let formattedTime = "";
let formattedTimeM = "";
let formattedTimeD = "";
let dt = "";
let month = "";
let year = "";
let devicetick = 8;

setTimeout(() => {
  fetch(process.env.REACT_APP_PRICE_API_URL, { mode: "cors" })
    .then((response) => response.json())
    .then((fdata) => {
      fdatax = fdata;
    });
}, 2000);

function gettime(thetimex) {
  const date = new Date(Number(thetimex) * 1000);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  year = date.getFullYear();
  month = months[date.getMonth()];
  dt = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  formattedTime = `${year}-${month}`;
  formattedTimeM = `${year}-${month}-${dt}`;
  formattedTimeD = `${year}-${month}-${dt} ${hours}:${minutes}`;
  return { Default: formattedTime, Month: formattedTimeM, Day: formattedTimeD };
}

function getchartdata(val, valtime) {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    devicetick = 5;
  }
  const ctx = document.getElementById("myChart").getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, 352);
  gradient.addColorStop(0, "rgba(226, 32, 174, 0.3)");
  gradient.addColorStop(1, "rgba(226, 32, 174, 0.1)");

  // Below gradient color for price chart

  const gradient2 = ctx.createLinearGradient(0, 0, 0, 352);
  gradient2.addColorStop(0, "rgba(31, 36, 230, 0.3)");
  gradient2.addColorStop(1, "rgba(31, 36, 230, 0.1)");

  let labels = [];
  let dataz = Object.values(fdatax[String(val)]);

  if (valtime == "default") {
    Object.keys(fdatax[String(val)]).map((thetime, i) => {
      labels.push(gettime(thetime).Default);
    });
  } else if (valtime == "year") {
    Object.keys(fdatax[String(val)]).map((thetime, i) => {
      labels.push(gettime(thetime).Default);
    });

    const newlb = [];
    const newdataz = [];
    for (let t = 0; t < labels.length; t++) {
      if (labels[t].includes(year) == true) {
        newlb.push(labels[t]);
        newdataz.push(dataz[t]);
      }
    }
    labels = newlb;
    dataz = newdataz;
  } else if (valtime == "month") {
    Object.keys(fdatax[String(val)]).map((thetime, i) => {
      labels.push(gettime(thetime).Month);
    });
    const newlb = [];
    const newdataz = [];
    for (let t = 0; t < labels.length; t++) {
      if (
        labels[t].includes(month) == true &&
        labels[t].includes(year) == true
      ) {
        newlb.push(labels[t]);
        newdataz.push(dataz[t]);
      }
    }
    labels = newlb;
    dataz = newdataz;
  } else if (valtime == "day") {
    Object.keys(fdatax[String(val)]).map((thetime, i) => {
      labels.push(gettime(thetime).Day);
    });
    const newlb = [];
    const newdataz = [];
    for (let t = 0; t < labels.length; t++) {
      if (
        labels[t].includes(dt) == true &&
        labels[t].includes(month) == true &&
        labels[t].includes(year) == true
      ) {
        newlb.push(labels[t]);
        newdataz.push(dataz[t]);
      }
    }
    labels = newlb;
    dataz = newdataz;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: val,
        data: dataz,
        fill: "start",
        backgroundColor: gradient,
        // backgroundColor: 'rgb(226, 32, 174, 0.2)',
        tension: 0.6,
        borderColor: "rgba(226, 32, 174)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(226, 32, 174, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(226, 32, 174, 1)",
        pointHoverBorderColor: "rgba(226, 32, 174, 1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  // Below data2 color for price chart

  const data2 = {
    labels: labels,
    datasets: [
      {
        label: val,
        data: dataz,
        fill: "start",
        backgroundColor: gradient2,
        tension: 0.6,
        borderColor: "rgba(31, 36, 230)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(31, 36, 230, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(31, 36, 230, 1)",
        pointHoverBorderColor: "rgba(31, 36, 230, 1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    hover: {
      mode: "nearest",
      intersect: true,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: devicetick,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          limits: {
            xy: { min: 0, max: 0 },
          },
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
    },

    animation: {
      x: {
        duration: 2000,
        from: 0,
      },
      y: {
        duration: 2000,
        from: 500,
      },
    },
  };

  const config = { type: "line", data: data, options: options };
  Chartx = new Chart(ctx, config);
}
setTimeout(() => {
  getchartdata("price", "default");
}, 3000);

function pricef() {
  Chartx.destroy();
  Chartx = "";
  currentchart = "price";
  getchartdata(currentchart, "default");
}

function quantityf() {
  Chartx.destroy();
  Chartx = "";
  currentchart = "quantity";
  getchartdata(currentchart, "default");
}

function onDay() {
  Chartx.destroy();
  Chartx = "";
  getchartdata(currentchart, "day");
}

function onMonth() {
  Chartx.destroy();
  Chartx = "";
  getchartdata(currentchart, "month");
}

function onYear() {
  Chartx.destroy();
  Chartx = "";
  getchartdata(currentchart, "year");
}

function onReset() {
  Chartx.destroy();
  Chartx = "";
  getchartdata(currentchart, "default");
}

export default function PQChart(props) {
  return (
    <>
      <section className="p-1 p-md-3 mt-4 chartmode rounded">
        {/* <Button className="charbtn me-2" variant="outline-danger" size="sm" ></Button> */}
        {/* <Button className="charbtn" variant="outline-success" size="sm" ></Button> */}

        <div className="row d-flex justify-content-between align-items-center mb-3">
          <div className="col-5 col-md-6 ps-2 ps-md-3 text-start text-md-start">
            <ButtonGroup className="charfilter" aria-label="Basic example">
              <Button
                className="charbtn char-btn-blue"
                variant="primary"
                onClick={pricef}
              >
                Price
              </Button>
              <Button
                className="charbtn char-btn-pink"
                variant="primary"
                onClick={quantityf}
              >
                Quantity
              </Button>
              {/*<Button className="charbtn" variant="secondary">Right</Button>*/}
            </ButtonGroup>
          </div>
          <div className="col-7 col-md-6 pe-2 pe-md-3 text-end text-md-end">
            <ButtonGroup className="charfilter" aria-label="Basic example">
              <Button
                className="charbtn char-btn"
                variant="primary"
                onClick={onDay}
              >
                1D
              </Button>
              <Button
                className="charbtn char-btn"
                variant="primary"
                onClick={onMonth}
              >
                1M
              </Button>
              <Button
                className="charbtn char-btn"
                variant="primary"
                onClick={onYear}
              >
                1Y
              </Button>
              <Button
                className="charbtn char-btn"
                variant="primary"
                onClick={onReset}
              >
                Reset
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <canvas id="myChart"></canvas>
      </section>
    </>
  );
}
