import React from "react";
import { Container, Row, Col, Image, Table, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import LogoBlue from "../assets/img/logo-blue.png";
import calculation1 from "../assets/img/Whitepaper-calculation1.png";
import calculation2 from "../assets/img/Whitepaper-calculation2.png";
import { AiFillHome } from "react-icons/ai";
import { BsCloudArrowDownFill } from "react-icons/bs";
const Whitepaper = () => {
  return (
    <>
    <section>
      <div className="home-fixed">
      <Nav defaultActiveKey="/home" className="flex-column">
        <Link to="/" className="nav-link"><AiFillHome size={20}/></Link>
        <a className="nav-link text-decoration-none" target="_blank" href="https://orio.io/OSPC_Whitepaper.pdf"><BsCloudArrowDownFill size={20}/></a>
      </Nav>
      </div>
    </section>
      <section className="py-5">
        <Container>
          <Row className="text-center py-5 text-black">
            <Col>
              <Image src={LogoBlue} className="img-fluid" />
              <h1 className="pt-5 fw-900">Orio Stable Progressive Currency</h1>
              <h1 className="pb-5 fw-900">(OSPC)</h1>
              <h2 className="py-5 fw-700">
                An inflation proof global currency beyond <br /> the U.S. Dollar
              </h2>
              <p className="py-5 fw-600">
                Wilfried Kouadio ({" "}
                <a href="#" className="text-purple text-decoration-none">
                  wilfried.k@orio.io
                </a>{" "}
                )
              </p>
              <p className="mb-1 fw-600">Version 1.0</p>
              <p className="mb-1 fw-600">First Published: October 27th, 2022</p>
              <p className="mb-1 fw-600">
                <a href="#" className="text-purple text-decoration-none">
                  www.orio.io
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-600 text-purple mb-4">CONTENTS</h2>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>
                      <a
                        href="#abstract"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        {" "}
                        ABSTRACT
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#introDuct"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        {" "}
                        INTRODUCION: Why does a store of value currency beyond
                        the US Dollar and Stablecoin matters?
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#oriStable"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        {" "}
                        HOW ORIO STABLE PROGRESSIVE CURRENCY IMPLEMENTS
                        (BECOMES) AN EVER-GROWING STORE OF VALUE RESISTANT TO
                        INFLATION:
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <a
                            href="#taxFees"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            1- 1% tax fees paid at each entry and exit
                            transaction (when USD is converted to ORIO vice
                            versa)
                          </a>
                        </li>
                        <li>
                          <a
                            href="#inicialPrice"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            2- Initial conventional price{" "}
                          </a>
                        </li>
                        <li>
                          <a
                            href="#priceFormula"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            3- Price Formula
                          </a>
                        </li>
                        <li>
                          <a
                            href="#supplyMechnis"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            4- EIS and fair supply mechanism
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#curruncySystem"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        {" "}
                        IMPORTANCE OF FULLY DECENTRALIZED STORE OF VALUE
                        CURRENCY SYSTEM:
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <a
                            href="#excaBusin"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            1- Remove the focus around exchange businesses
                          </a>
                        </li>
                        <li>
                          <a
                            href="#ContsBetwen"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            2- Contrast between existing financial system where
                            people are charge overdraft fees, get their account
                            censored (Suspended) and an open financial system.
                          </a>
                        </li>
                        <li>
                          <a
                            href="#OpenSoarce"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            3- Made open source and available to every developer
                          </a>
                        </li>
                        <li>
                          <a
                            href="#conCeptOf"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            4- The concept of decentralization should be beyond
                            protocol, the implementation and distribution should
                            also be decentralized
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#PriceTranspera"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        {" "}
                        PRICE TRANSPARENCY
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <a
                            href="#PriceFunction"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            1- Price function built-in smart contract
                          </a>
                        </li>
                        <li>
                          <a
                            href="#BuildTrust"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            2- The importance to show transparence and build
                            trust
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#AduaCate"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        {" "}
                        ADEQUATE INNOVATION NECESSARY TO CREATE MASS ADOPTION:
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <a
                            href="#safeKing"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            1- How the safekeeping of private keys is an issue
                            for majority of crypto holders, and scares new
                            incumbents
                          </a>
                        </li>
                        <li>
                          <a
                            href="#creTprive"
                            className="text-purple fw-500 text-decoration-none"
                          >
                            {" "}
                            2- Creation of a private keyless system made
                            available to everyone
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#CONCLUSION"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        CONCLUSION
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#CONTACT"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        CONTACT
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#REFERENCE"
                        className="text-purple fw-500 text-decoration-none"
                      >
                        REFERENCE
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="text-black">
        <Container>
          <Row>
            <Col className="text-black">
              <h2 className="fw-600 text-purple mb-4" id="abstract">
                ABSTRACT
              </h2>
              <p className="text-justify">
                When Bitcoin first appeared in 2009, it introduced a
                decentralized (blockchain-based) ledger for transaction
                execution, record keeping and created a now-commonly used
                currency independent of all sovereign monetary authorities.
                Today, there are thousands of similar decentralized
                cryptocurrencies, and their combined daily worldwide transaction
                volume exceeds $1 Trillion.
              </p>
              <p className="text-justify">
                Although the combined market value of these cryptocurrencies
                exceeded $3 trillion at a point, their usefulness as a practical
                method of value exchange has hindered by their extreme price
                volatility, strong price correlation to Bitcoin, and often slow
                transaction confirmation times. Stablecoin has been created to
                address these drawbacks (i.e., eliminating volatility) by
                pegging their value to a unit of underlying assets, often
                available on all blockchains. And fully or partially backed by
                state-issued fiat currencies (like the Dollar, Pound, Euro, INR,
                FCFA or Yen), highly liquid reserves (like government
                treasuries), or commodities like precious metals.{" "}
              </p>
              <p className="text-justify">
                Stablecoin development has received a lot of interest in the
                cryptocurrency space. However, compared to stablecoin
                development, the development of an inflation-resistant,
                store-of-value cryptocurrency has received very little
                attention.
              </p>
              <p className="text-justify">
                With inflation currently at a 40-year high (8.2%), the creation
                of an inflation-resistant, store-of-value cryptocurrency is
                crucial.
              </p>
              <p className="text-justify">
                In this whitepaper, we introduce ORIO the world’s first stable
                progressive currency that aims to provide not only the same
                advantages of stablecoins but also a currency with a built-in
                growth mechanism that will allow a continuous increase in price
                value as a result, be a currency that is inflation proof.
              </p>
 
              <h3 className="fw-600 text-purple py-4 text-justify" id="introDuct">
                INTRODUCION: Why does a store of value currency beyond the US
                Dollar and Stablecoin matters?{" "}
              </h3>

              <p className="text-justify">
                Economies around the world are facing a motley of challenges
                caused by rising inflation. High inflation devalues national
                currencies, which, in turn, heightens the cost of living,
                especially in scenarios where earnings remain unchanged.{" "}
              </p>
              <p className="text-justify">
                In the United States, the government has responded aggressively
                to inflation. The nation hit a 9.1% inflation rate in June 2022,
                prompting the Federal Reserve to implement a series of fiscal
                countermeasures designed to prevent the economy from
                overheating. Hiking interest rates was one of the
                countermeasures. Soaring Fed interest rates have consequently
                slowed down consumer spending and business growth in the
                country.{" "}
              </p>
              <p className="text-justify">
                The counter-inflation approach has also strengthened the value
                of the U.S. dollar against other currencies due to tight dollar
                liquidity checks. As 79.5% of all international trades are
                undertake using the dollar [1], many countries are now paying a
                premium for imports to compensate for the dollar's rising value,
                worsening inflation in the importing countries. This problem is
                especially true for people native to countries like Turkey,
                Argentina, Ethiopia, Zimbabwe, or Lebanon, where hyperinflation
                has deemed their fiat currencies a risky medium of exchange.
              </p>
              <p className="text-justify">
                Other than the threat of a possible recession, these
                inflationary pressures have a negative impact on the value of
                fiat currency in the hands of consumers and highlight the
                crucial need for financial tools or assets that can act as a
                hedge against inflation.
              </p>
              <p className="text-justify">
                In this whitepaper, we introduce ORIO, the world’s first stable
                progressive currency, a global currency system with a built-in
                growth mechanism that based on the transactions flow. With the
                aim of creating an ever-growing store of value that protects
                purchasing power in any economic condition.
              </p>
              <p className="text-justify">Essentially, we discuss the following subjects:</p>
              <ul className="text-justify ps-3">
                <li className="mb-3">
                  HOW ORIO STABLE PROGRESSIVE CURRENCY IMPLEMENTS (BECOMES) AN
                  EVER-GROWING STORE OF VALUE RESISTANT TO INFLATION: A detailed
                  explanation of how the stable progressive works and how it
                  only increases in value.
                </li>
                <li className="mb-3">
                  IMPORTANCE OF FULLY DECENTRALIZED STORE OF VALUE CURRENCY
                  SYSTEM: A true definition of a decentralized system accessible
                  to everyone everywhere.
                </li>
                <li className="mb-3">
                  PRICE TRANSPARENCY: Presenting, a truly transparent price
                  system trackable directly from a smart contract.
                </li>
                <li className="mb-3">
                  ADEQUATE INNOVATIONS NECESSARY TO CREATE MASS ADOPTION:
                  Presenting new suites of innovations that will ameliorate the
                  user experience and give people confidence while using
                  cryptocurrency products.
                </li>
              </ul>

              <h3 className="fw-600 text-purple py-4 " id="oriStable">
                HOW ORIO STABLE PROGRESSIVE CURRENCY IMPLEMENTS (BECOMES) AN
                EVER-GROWING STORE OF VALUE RESISTANT TO INFLATION:
              </h3>

              <p className="text-justify">
                For decades, we have witnessed payment networks like Visa and
                MasterCard monopolizing digital payments. In fact, for every
                debit and credit transaction, an average of 2% to 3% is charged.
                In 2021, Visa processed $13 Trillion in transaction volume and
                had net revenue of $24 billion.{" "}
              </p>
              <p className="text-justify">
                Additionally, the crypto industry is rapidly growing, with more
                interest in the cryptocurrency exchange business model. On
                average, the top ten cryptocurrency exchanges realize $3 million
                per day in profit. On average, the top ten cryptocurrency
                exchanges realize $3 million per day in profit. Considering that
                cryptocurrency did not even exist until 2009, a daily profit of
                $3 million for cryptocurrency exchanges is astonishing compared
                to the gradual historical development of most industries.{" "}
              </p>
              <p className="text-justify">
                The business strategy of cryptocurrency exchanges is
                straightforward; they generate revenue by charging transaction
                fees (for instance, sell, buy, and withdraw transaction fees).
                For the first time, the total trading revenue earned by
                cryptocurrency exchanges will surpass that of established stock
                exchanges like the New York Stock Exchange and the Nasdaq,
                according to a forecast from financial-services consultancy
                Optimas [2], which was reported in a MarketWatch article.
              </p>
              <p className="text-justify">
                The value offered to the end users by these crypto exchanges and
                payment networks is exploited and profiteering is the ultimate
                driving force, not customer satisfaction.
              </p>
              <p className="text-justify">
                Our concept of Stable Progressive currency aims at creating a
                trusted global currency system where people and businesses can
                trade freely while offering a robust and more secure network.{" "}
              </p>
              <h4 className="fw-600 text-purple py-4" id="taxFees">
                1- 1% tax fees paid at each entry and exit transaction (when USD
                is converted to ORIO vice versa)
              </h4>
              <p className="text-justify">
                Instead of enriching the exploitative payment networks and
                cryptocurrencies exchanges, we proposed a 1% Tax fees model
                charge on each transaction (each time US dollar is converted to
                ORIO, or vice-versa), this 1% transaction fee would be used as
                growth mechanism for the ORIO stable progressive currency
                ecosystem.{" "}
              </p>
              <p className="text-justify">
                The 1% fee has been strategically decided for the ORIO stable
                progressive currency to remain a medium of exchange. If the Tax
                fee is higher than the 1% threshold, users will only use the
                currency more as a saving engine than a medium of exchange.
                Also, it could create a risk where users will feel they are
                paying a considerable amount on transactions, hence abandoning
                the protocol. That is why it is important for the Tax fee to be
                at a negligible 1% while we continuously generate growth and
                create a formidable store of value.
              </p>
              <p className="text-justify">
                The Orio stable progressive protocol is built on the already
                innovative stablecoin. PAXOS (USDP) and BUSD are the stablecoins
                adopted as the medium of exchange on the protocol because they
                are trusted and adequately collateralized with real cash.
              </p>
              <p className="text-justify">
                We strongly believe that the growth mechanism empowered by the
                1% tax fee will create the most powerful currency the world has
                ever seen.
              </p>

              <h4 className="fw-600 text-purple py-4" id="inicialPrice">
                2- Initial conventional price
              </h4>

              <p className="text-justify">
                To ensure that the system operates in parity with the US dollar,
                we programmatically decided an initial price of 1 ORIO = $1 USD.
                As a result, with each transaction, the value of 1 ORIO will
                rise above $1 USD, enhancing its purchasing power relative to
                the US dollar. If this situation were to repeat itself every
                time there was a transaction, the value of 1 ORIO would rapidly
                increase into a vast sum of money.
              </p>

              <h4 className="fw-600 text-purple py-4" id="priceFormula">
                3- Price Formula
              </h4>

              <p>
                The 1% tax fee charged at every transaction is shared as
                follows:
              </p>
              <ul>
                <li>- 60% shared between all Holders</li>
                <li>- 40% to the ORIO Treasury.</li>
              </ul>
              <p>
                Anytime a fee is shared among the holders, the price of ORIO
                will increase.
              </p>
              <p className="text-justify">
                In every scenario, ORIO will continuously increase no matter the
                transaction (buy or sell), which is the idea of the Stable
                Progressive Currency.
              </p>

              <h3 className="fw-600 text-purple py-4">PRICE FORMULA</h3>
              <h4 className="fw-600 text-purple py-4">BUY ORIO:</h4>
              <Image src={calculation2} className="img-fluid" />
              <p>With,</p>
              <p>
                P<sub>n</sub>: The price of the nth transaction
              </p>
              <p>
                P<sub>(n-1)</sub>: The price of the nth previous transaction{" "}
              </p>
              <p>Tf: transaction fee: (1%) in BUSD</p>
              <p>TSE: total existing supply</p>
              <p>
                TSE<sub>(n-1)</sub>: total existing supply at previous
                transaction{" "}
              </p>
              <p>TPA: Total purchase amount including fees in (BUSD) </p>
              <p>TREF: Treasury fees (0.4%) in BUSD</p>

              <h4 className="fw-600 text-purple py-4">SELL ORIO: </h4>
              <Image src={calculation1} className="img-fluid" />
              <p>With,</p>
              <p>Tss: Total supply being sold in (ORIO)</p>
              <p>
                Tfee: Total fee of the transaction being sold in ORIO, and which
                is always 1% of the amount sold.
              </p>

              <h4 className="fw-600 text-purple py-4" id="supplyMechnis">
                4- EIS and fair supply mechanism
              </h4>

              <p className="text-justify">
                Almost all cryptocurrencies have an arbitrary supply, even
                bitcoin. ORIO is built with solid transparency fundamentals. In
                order to be transparent and create a trustless system, we deploy
                a smart contract that manages the supply of ORIO according to
                the transaction flow on the protocol.{" "}
              </p>
              <p className="text-justify">
                The ORIO smart contract at launch (i.e., at deployment) had a
                total supply of ZERO ORIO. The EIS (Elastic Infinite Supply)
                protocol embedded in its smart contract mints and burns the ORIO
                token in real-time as transactions happen. Due to the EIS
                mechanism, the ORIO stable progressive currency is the first and
                only cryptocurrencies to have the total supply always equals the
                circulating supply.{" "}
              </p>
              <p className="text-justify">
                The fair supply mechanism is designed to ensure transparency of
                the ORIO Stable Progressive currency system. This is different
                from the system where a particular number of tokens are randomly
                generated, which always gives the advantage to the founding
                team.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <h3 className="fw-600 text-purple py-4" id="curruncySystem">
                IMPORTANCE OF FULLY DECENTRALIZED STORE OF VALUE CURRENCY
                SYSTEM:
              </h3>

              <h4 className="fw-600 text-purple py-4" id="excaBusin">
                1- Remove the focus around exchange businesses
              </h4>

              <p className="text-justify">
                The idea behind bitcoin is to right the wrong created by the
                failed global monetary policy that makes people experience
                economic hardship caused by inflation. However, the original
                bitcoin idea has been diluted (due in part to some fundamental
                issue in its system). Bitcoin is majorly used as a speculative
                tool and this allows exchange businesses to thrive; the exchange
                platforms are where almost every cryptocurrency activity is
                done.{" "}
              </p>
              <p className="text-justify">
                ORIO Stable Progressive Currency would be the vehicle to help
                cushion the effect of inflation on people globally. It will
                hedge against inflation and, simultaneously, allow billions of
                people to trade freely and seamlessly.
              </p>

              <h4 className="fw-600 text-purple py-4" id="ContsBetwen">
                2- Contrast between existing financial system where people are
                charge overdraft fees, get their account censored (Suspended)
                and an open financial system.
              </h4>

              <p className="text-justify">
                The need for a truly decentralized financial system other than
                just a decentralized protocol is more important today than it
                has ever been. The current financial system gives exceeding
                power to financial institutions in deciding who gets access to
                financial instruments.
              </p>
              <p className="text-justify">
                The US financial system is one of the most unethical. For
                instance, Banks may authorize your account to pay for a
                transaction that your account doesn’t have the required funds to
                fulfill. Then, if you fail to bring your account balance update
                within, usually, 24 hours, the bank will charge an overdraft
                fee. The bank will continue to charge your account for 30 to 60
                days, after which they will report you to the ChexSystem
                database. ChexSystems ensures that every reported account is
                unable to open an account in another financial institution for a
                period of usually three years.
              </p>
              <p className="text-justify">
                The unbanked population of America is significant because of the
                greedy system that seeks to generate more revenue for financial
                institutions at the expense of the customers. The unbanked
                population in other parts of the world, most especially Africa,
                have accessibility problems. Orio Stable Progressive currency is
                also a solution to the problem of accessibility because it can
                be accessed anywhere in the world where there is mobile/internet
                facility. Our proposed global currency system allows anyone,
                anywhere in the world to receive and spend money without the
                need of owning a bank account. We partner with Sendwyre, which
                has a partnership with MoneyGram. MoneyGram is present in more
                than 195 countries. People would be able to buy or withdraw
                their Orio through Sendwyre if they are not able to use crypto
                or do it directly from a bank account.
              </p>
              <p className="text-justify">
                This is a mechanism to take financial power from the overly
                greedy, centralized financial institution and return it to the
                people where it originally belongs.{" "}
              </p>

              <h4 className="fw-600 text-purple py-4" id="OpenSoarce">
                3- Made open source and available to every developer
              </h4>

              <p className="text-justify">
                The ORIO stable progressive currency system is an open-source
                protocol that will allow developers around the world to
                integrate it into their diverse applications, from DeFi to
                sharing economy applications and many more.
              </p>

              <h4 className="fw-600 text-purple py-4" id="conCeptOf">
                4- The concept of decentralization should be beyond protocol,
                the implementation and distribution should also be
                decentralized:
              </h4>

              <p className="text-justify">
                Decentralization should ensure that Venture capital and hedge
                funds do not have absolute control of the protocols; if VCs
                control the decentralized protocol/exchanges, then we have not
                moved an inch from the old order. A global currency like Orio
                should focus on greater goods for greater numbers rather than
                the balance sheet of VCs and hedge funds.
              </p>
              <h3 className="fw-600 text-purple py-4" id="PriceTranspera">
                PRICE TRANSPARENCY
              </h3>
              <h4 className="fw-600 text-purple py-4" id="PriceFunction">
                1- Price function built-in smart contract
              </h4>
              <p className="text-justify">
                The first interaction with cryptocurrency for many happened
                through exchange platforms. The vast majority of all blockchain
                transactions entail cryptocurrency moving to and from exchanges,
                and they function as the most common on-ramp to cryptocurrency
                for first-time investors. But the widespread issue of faked
                trade volumes has created trust issues among exchanges, their
                users, and the rest of the industry — not to mention the
                financial institutions and regulators who still eye
                cryptocurrency with caution.
              </p>
              <p className="text-justify">
                The problem is enormous; earlier this year, Bitwise Asset
                Management reported that about 95% of all reported trade volume
                is fake [3]. Trading on exchanges largely happens off-chain and
                is recorded in exchanges’ order books, which means that the
                volumes they self-report are easily faked or subject to wash
                trading. And the incentives to report fake volumes are clear:
                Higher trading volume suggests greater liquidity and market
                activity, which is useful for drawing in new users and
                attracting new coins looking to list. Trade volume increases
                also help exchanges rank high on the public industry data
                aggregators like CoinMarketCap, giving them more visibility.
              </p>
              <p className="text-justify">
                The crypto ecosystem suffers from these unethical practices.
                Faked trade volumes create bad images and sow doubt amongst
                potential investors.
              </p>
              <p className="text-justify">
                The ORIO stable progressive currency system is the first and
                only smart contract with a built-in price function. This price
                function ensures the transparency of the ORIO currency. ORIO
                does not depend on the activities that happen on exchanges,
                which are not trusted to regulate its price.
              </p>
              <h4 className="fw-600 text-purple py-4" id="BuildTrust">
                2- The importance to show transparence and build trust
              </h4>
              <p className="text-justify">
                The price transparency of the ORIO stable Progressive will
                facilitate fast adoption all over the world. ORIO would change
                the usually manipulated and poorly governed currencies, which
                has been offered to people for centuries.
              </p>

              <h3 className="fw-600 text-purple py-4" id="AduaCate">
                ADEQUATE INNOVATION NECESSARY TO CREATE MASS ADOPTION:
              </h3>
              <h4 className="fw-600 text-purple py-4" id="safeKing">
                1- How the safekeeping of private keys is an issue for majority
                of crypto holders, and scares new incumbents
              </h4>
              <p className="text-justify">
                Today, the user experience of cryptocurrency is poor. One of the
                common issues is the safety of private keys. If a user loses or
                gets their private key stolen, their funds are gone. While in
                the traditional financial system, users have the support of
                their financial institutions if they lose access to their
                accounts. In the US, user accounts at most financial
                institutions are FDIC (Federal Deposit Insurance Corporation)
                insured, which protects their funds in case any problems arise.{" "}
              </p>
              <p className="text-justify">
                For innovative concepts such as cryptocurrency to achieve mass
                adoption and become the engine of the financial economy, we
                should prioritize innovation on improved user experience and
                offer users peace of mind. The team at ORIO lab prioritizes the
                creation of tools and products that will improve user experience
                and users’ peace of mind.
              </p>
              <h4 className="fw-600 text-purple py-4" id="creTprive">
                2- Creation of a private keyless system made available to
                everyone{" "}
              </h4>
              <p className="text-justify">
                One example of the innovative product that the ORIO team has
                built is a private keyless wallet. Users will longer face the
                stress and difficulty of safekeeping a wallet private key ever
                again. We engineered a system based on AI, our native
                quantum-proof cryptography (DMPC Delegated Multi–Party
                Computation), and biometrics to make the keyless wallet
                possible. We will publish the details of the private keyless
                wallet in a different paper. If you are familiar with product
                offerings that Fireblocks charges $100 of million for, our
                product is a much more sophisticated technology, and will be
                made available free of charge and open source for everyone to
                use.{" "}
              </p>
              <h3 className="fw-600 text-purple py-4" id="CONCLUSION">
                CONCLUSION
              </h3>
              <p className="text-justify">
                After Failing attempt of Ecash, Bitcoin, Stablecoin, and CBDC,
                to offer a safer medium of exchange and store of value to people
                globally, we believe today we have created the ORIO STABLE
                PROGRESSIVE CURRENCY SYSTEM (OSPCS), a more logical medium of
                exchange that will protect purchasing power for people and now
                offer them a saving opportunity that works in any market
                conditions.
              </p>
              <p className="text-justify">
                We now believe that the Warren Buffet, Charlie Munger of the
                world who once said bitcoin was a “rat poison” will inarguably
                agree that our innovative ORIO stable progressive currency
                system is the holy grail of all currency.
              </p>
              <p className="text-justify">
                Finally, as humans we have the right to earn and the right to
                protect the purchasing power of these earnings and not having
                our purchasing power decimated by poor monetary policy.
              </p>
              <h3 className="fw-600 text-purple py-4" id="CONTACT">
                CONTACT
              </h3>
              <p className="text-justify">
                For anyone interested in joining us to bring our world changing
                global currency to the mass, feel free to email{" "}
                <a href="#" className="text-purple text-decoration-none fw-500">
                  contact@orio.io.
                </a>{" "}
                To stay up to date with all the innovations from the ORIO lab,
                please visit{" "}
                <a href="#" className="text-purple text-decoration-none fw-500">
                  {" "}
                  https://orio.io
                </a>{" "}
                or to get engage directly with the ORIO community join us on
                Twitter at{" "}
                <a href="#" className="text-purple text-decoration-none fw-500">
                  https://www.twitter.com/orioinc{" "}
                </a>{" "}
              </p>
              <h3 className="fw-600 text-purple py-4" id="REFERENCE">
                REFERENCE
              </h3>
              <p>
                [1] Elizabaeth Gail (Cointelegraph:{" "}
                <a href="#" className="text-purple text-decoration-none fw-500">
                  {" "}
                  https://cointelegraph.com/news/global-inflation-mounts-how-stablecoins-are-helping-protect-savings{" "}
                </a>{" "}
                )
              </p>
              <p>
                [2] Chris Matthewa (Marketwatch :{" "}
                <a href="#" className="text-purple text-decoration-none fw-500">
                  {" "}
                  https://www.marketwatch.com/story/crypto-exchange-revenue-grew-700-in-2021-surpassing-traditional-exchanges-like-nyse-report-11647286545{" "}
                </a>{" "}
                )
              </p>
              <p>
                [3] Craig Adeyanju (Cointelegraph:{" "}
                <a href="#" className="text-purple text-decoration-none fw-500">
                  https://cointelegraph.com/news/bitwise-calls-out-to-sec-95-of-bitcoin-trade-volume-is-fake-real-market-is-or{" "}
                </a>{" "}
                ){" "}
              </p>
              <h2 className="fw-900 text-purple py-5 my-5 text-center">
                Thank You
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Whitepaper;
