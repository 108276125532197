import React from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Tabs,
  Tab,
  Button,
  Image,
} from "react-bootstrap";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

import SwapForm from "../component/SwapForm";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Hero from "../assets/img/hero-title4.png";
import Icon1 from "../assets/img/ico-1.png";
import Icon2 from "../assets/img/ico-2.png";
import Icon3 from "../assets/img/ico-3.png";
import Icon4 from "../assets/img/ico-4.png";
import game from "../assets/img/game.png";
import CNft from "../assets/img/connectnft.png";
import comingwap from "../assets/img/comingwap.png";

// import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Header />

      <section className="hero-banner">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6}>
              <div className="text-center text-md-start mb-4 mb-md-0 maincount">
                <h1 className="text-white mb-4 hero-title fw-bold">
                  THE <span className="text-purple"> WORLD’S FIRST</span> STABLE
                  PROGRESSIVE CURRENCY
                </h1>
                <p className="text-white mb-4">
                  A true store of value currency that move at speed of the
                  internet
                </p>
                <Link to="whitepaper" className="main-btn">
                  Read WhitePaper
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <Image src={Hero} className="img-fluid heroimg" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5 bg-black-shade2">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={8} className="text-center text-md-start">
              <h2 className="text-white mb-4 display-6 fw-bold text-center text-uppercase">
                FRICTIONLESS CURRENCY BEYOND{" "}
                <span className="text-purple-heart">
                  THE U.S DOLLAR & STABLECOINS
                </span>
              </h2>
              <p className="text-white text-center">
                ORIO is a trustworthy store of value and inflation-proof
                currency that continuously increase in value as the transactions
                are happening
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-start align-items-start mt-5">
            <Col md={6} lg={3}>
              <Card className="flycard mb-4 mb-md-0">
                <Card.Body>
                  <Image className="img-fluid mb-4 w-60" src={Icon1} />
                  <div className="">
                    <Card.Title className="mb-3">
                      Resistant To Any Economic Conditions
                    </Card.Title>
                    <Card.Text className="text-justify">
                      A 1% tax fee paid at each transaction is responsible for
                      the progressive price increase, therefore making it a
                      robust store of value
                    </Card.Text>
                  </div>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  <BsArrowRightCircle size={18} className="text-purple" />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={6} lg={3}>
              <Card className="flycard mb-4 mb-md-0">
                <Card.Body>
                  <Image className="img-fluid mb-4 w-60" src={Icon2} />
                  <div className="">
                    <Card.Title className="mb-3">
                      The Most Transparent Price From All Cryptocurrencies{" "}
                    </Card.Title>
                    <Card.Text className="text-justify">
                      Price is embedded directly from the smart contract,
                      doesn’t depend on the activities on exchanges
                    </Card.Text>
                  </div>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  <BsArrowRightCircle size={18} className="text-purple" />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={6} lg={3}>
              <Card className="flycard mb-4 mb-md-0">
                <Card.Body>
                  <Image className="img-fluid mb-4 w-60" src={Icon3} />
                  <div className="">
                    <Card.Title className="mb-3">
                      Fully Accessible Globally
                    </Card.Title>
                    <Card.Text className="text-justify">
                      Can be purchase with your usual payment methods, credit
                      card, bank account or stablecoin) directly from orio.io
                      and doesn’t require any exchanges
                    </Card.Text>
                  </div>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  <BsArrowRightCircle size={18} className="text-purple" />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={6} lg={3}>
              <Card className="flycard mb-4 mb-md-0">
                <Card.Body>
                  <Image className="img-fluid mb-4 w-60" src={Icon4} />
                  <div className="">
                    <Card.Title className="mb-3">
                      100% Backed and Non-Custodial{" "}
                    </Card.Title>
                    <Card.Text className="text-justify">
                      Users remain in custody of their funds while the funds are
                      n the smart contract
                    </Card.Text>
                  </div>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  <BsArrowRightCircle size={18} className="text-purple" />
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-0 pt-md-0 gamechange text-white">
        <div className="gamemain py-5 mt-0 mt-md-3">
          <Container>
            <Row className="d-flex justify-content-center align-items-center mt-0 mt-md-5">
              <Col md={6} className="mb-5 mb-md-0">
                <Image className="img-fluid oriowebjac" src={game} />
              </Col>
              <Col md={6}>
                <h2 className="text-white mb-4 display-6 fw-600 text-uppercase">
                  ORIO IS A
                  <span className="text-purple ms-2">GAME CHANGER</span>
                </h2>
                <p className="text-white text-justify">
                  The ORIO stable progressive currency is a significant
                  breakthrough, far more revolutionary than bitcoin and
                  stablecoin. While stablecoins have allowed us to have a
                  digitized Dollar that moves at the speed of the internet, ORIO
                  takes things further, it doesn't only have all the
                  capabilities of stablecoin, but it has a price value built-in
                  growth mechanism.
                </p>
                <p className="text-justify">
                  Now with ORIO, people worldwide wouldn't have to rely on
                  poorly managed currencies; they can hold Orio and protect
                  their purchasing power as ORIO continuously increases in
                  value.
                </p>
                <Tabs
                  defaultActiveKey="our_mission"
                  id="uncontrolled-tab-example"
                  className="py-4 border-0 tabscusto"
                >
                  <Tab
                    eventKey="our_mission"
                    title="1% COMPOUNDING POWER"
                    className="py-0 text-white bg-transparent border-0"
                  >
                    <p className="text-justify">
                      The 1% Tax fees charged at each transaction will creat a
                      global economy of its own that will EMPOWER people
                      globally while giving them access to better savings
                      opportunities than the one offers by traditional banking
                      services.
                    </p>
                  </Tab>
                  <Tab
                    eventKey="our_advantage"
                    title="EMPOWERING PEOPLE"
                    className="py-0 text-white bg-transparent border-0"
                  >
                    <p className="text-justify">
                      The 1% Tax fees charged at each transaction will creat a
                      global economy of its own that will EMPOWER people
                      globally while giving them access to better savings
                      opportunities than the one offers by traditional banking
                      services.
                    </p>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section id="SwapSection">
        <SwapForm />
      </section>

      <section className="py-5 creshell text-white">
        <Container>
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Col md={5} className="text-center">
              <Image className="img-fluid nftpiramid" src={CNft} />
            </Col>
            <Col md={7} className="text-center text-md-start">
              <h2 className="text-white mb-4 display-6 fw-600">
                <span className="text-purple">BUY or SELL ORIO</span> STABLE
                PROGRESSIVE CURRENCY
              </h2>
              <h5 className="text-white mb-4">DIRECTLY FROM THE ORIO DAPP</h5>
              <p className="mb-4 text-justify">
                We built a seamless interface to allow everyone everywhere to
                buy or sell ORIO without needing an exchange. ORIO can be
                purchased with stablecoins ( USDT, USDC, USDP,BUSD,DAI), cash at
                MoneyGram ( available in 195 countries), or using a card and
                bank account through Sendwyre.
              </p>
              <Button variant="primary" className="main-btn">
                connect to D App
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 commingsoon text-white">
        <Container>
          <Row>
            <Col className="comemain">
              <Row className="d-flex justify-content-center align-items-center">
                <Col md={6} lg={8} className="px-5 py-5 py-md-4">
                  <h2 className="text-white mb-4 display-6 fw-bold">
                    <span className="text-purple">Coming Soon</span>
                  </h2>
                  <p className="mb-4">
                    A PRIVATE KEYLESS WALLET is coming soon to give you peace of
                    mind for all your assets.
                  </p>
                  <Button variant="primary" className="main-btn">
                    Connect with us
                  </Button>
                </Col>
                <Col
                  md={6}
                  lg={4}
                  className="comeimg p-5 d-flex justify-content-center align-items-center"
                >
                  <Image className="img-fluid orioweb" src={comingwap} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Home;
