import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Form,
  Tab,
  Tabs,
  InputGroup,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Button,
  Nav, 
} from "react-bootstrap";
import { BsArrowDownUp } from "react-icons/bs";
import _map from "lodash/map";

import WalletConnect from "./WalletConnect";
import {
  ORIO,
  BUSD,
  USDP,
  USDC,
  DAI,
  USDT,
  SWAPHELPER,
} from "./../common/contracts";
import PriceChart from "./PriceChart";

const SwapForm = () => {
  const { account, active, library } = useWeb3React();

  const [OrioContract, setOrioContract] = useState(null);
  const [BUSDContract, setBUSDContract] = useState(null);
  const [USDPContract, setUSDPContract] = useState(null);
  const [USDCContract, setUSDCContract] = useState(null);
  const [DAIContract, setDAIContract] = useState(null);
  const [USDTContract, setUSDTContract] = useState(null);
  const [swapHelperContract, setswapHelperContract] = useState(null);

  const [orioPrice, setOrioPrice] = useState(0);
  const [items, setItems] = useState(["BUSD", "USDP", "USDC", "DAI", "USDT"]);
  const [toCoin, setToCoin] = useState("BUSD");
  const [toBalance, setToBalance] = useState(0);
  const [fromCoin, setFromCoin] = useState("ORIO");
  const [fromBalance, setFromBalance] = useState(0);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const [txToAc, setTxToAc] = useState("");
  const [txAmt, setTxAmt] = useState("");

  const [txStatus, setTxStatus] = useState("");

  const tolerance = Number(process.env.REACT_APP_TOLERANCE || 15);

  const resetState = () => {
    setToCoin("BUSD");
    setFromCoin("ORIO");
    setFromValue(0);
    setToValue(0);
    setTxStatus("");
    setTxAmt("");
  };

  const getContract = (address, ABI) => {
    const contract = new ethers.Contract(address, ABI, library);

    return contract.connect(library?.getSigner());
  };

  const getTokenData = (key) => {
    switch (key) {
      case "ORIO":
        return {
          contract: OrioContract,
          DECIMALS: ORIO.DECIMALS,
          ADDRESS: ORIO.ADDRESS,
          PRICE: 1,
        };
      case "BUSD":
        return {
          contract: BUSDContract,
          DECIMALS: BUSD.DECIMALS,
          ADDRESS: BUSD.ADDRESS,
          PRICE: 1,
        };
      case "USDP":
        return {
          contract: USDPContract,
          DECIMALS: USDP.DECIMALS,
          ADDRESS: USDP.ADDRESS,
          PRICE: 1,
        };
      case "USDC":
        return {
          contract: USDCContract,
          DECIMALS: USDC.DECIMALS,
          ADDRESS: USDC.ADDRESS,
          PRICE: 1,
        };
      case "DAI":
        return {
          contract: DAIContract,
          DECIMALS: DAI.DECIMALS,
          ADDRESS: DAI.ADDRESS,
          PRICE: 1,
        };
      case "USDT":
        return {
          contract: USDTContract,
          DECIMALS: USDT.DECIMALS,
          ADDRESS: USDT.ADDRESS,
          PRICE: 1,
        };

      default:
        return { contract: null, DECIMALS: null, ADDRESS: null, PRICE: null };
    }
  };

  const getBalance = async (coin) => {
    const { contract, DECIMALS } = getTokenData(coin);

    if (contract) {
      const balance = ethers.utils.formatUnits(
        (await contract.balanceOf(account)).toString(),
        DECIMALS
      );

      return Number(balance).toFixed(4);
    }
  };

  const getBuyOrioToToken = (qty) => {
    qty = qty || 0;

    const _amt = qty * orioPrice + qty * orioPrice * 0.01;

    return _amt ? _amt.toFixed(4) : 0;
  };

  const getBuyTokenToOrio = (qty) => {
    qty = qty || 0;

    const _amt = qty / (qty * orioPrice + qty * orioPrice * 0.01).toFixed(4);

    return _amt ? (_amt * qty).toFixed(4) : 0;
  };

  const getSellOrioToToken = (qty) => {
    qty = qty || 0;

    const _amt = qty * orioPrice - qty * orioPrice * 0.01;

    return _amt ? _amt.toFixed(4) : 0;
  };

  const getSellTokenToOrio = (qty) => {
    qty = qty || 0;

    const _amt = qty / (qty * orioPrice - qty * orioPrice * 0.01);

    return _amt ? (_amt * qty).toFixed(4) : 0;
  };

  const buyOrio = async () => {
    try {
      const { contract, DECIMALS, ADDRESS } = getTokenData(toCoin);

      const buyQ = ethers.utils.parseUnits(fromValue.toString(), ORIO.DECIMALS);

      let maxSwapAmt = 0;
      let _fromAmount = fromValue;

      if (toCoin !== "BUSD") {
        const price = await OrioContract.price();

        const buyPrice = price
          .mul(buyQ)
          .div(ethers.utils.parseUnits("1", ORIO.DECIMALS));

        const IncPercent = await OrioContract.incPercent();
        const denominator = await OrioContract.denominator();

        let finalPrice = buyPrice.mul(IncPercent).div(denominator);

        const finalPrice_COIN =
          await swapHelperContract.callStatic.swapTokenStoB_fixedOutput_estimate(
            ADDRESS,
            finalPrice
          );

        const Inc = finalPrice_COIN.mul(tolerance).div(100);

        maxSwapAmt = finalPrice_COIN.add(Inc);

        _fromAmount = ethers.utils.formatUnits(maxSwapAmt, DECIMALS);
      }

      setTxStatus("checking allowance");
      const _allowance = await contract.allowance(account, ORIO.ADDRESS);
      const allowance = ethers.utils.formatUnits(
        _allowance.toString(),
        DECIMALS
      );
      if (Number(allowance.toString()) < Number(_fromAmount)) {
        setTxStatus("Setting Allowance...");
        const atx = await contract.approve(
          ORIO.ADDRESS,
          ethers.utils.parseUnits("1000000", DECIMALS).toString()
        );
        setTxStatus("Processig Allowance...");
        await atx.wait();
        setTxStatus("Allowance Processed...");
      }

      setTxStatus("Confirm Transaction on Wallet");

      const tx = await OrioContract.buy(buyQ, ADDRESS, maxSwapAmt);

      setTxStatus("Waiting For Confirmation");

      await tx.wait();

      resetState();

      setTxStatus("Transaction Completed");

      setFromBalance(await getBalance(fromCoin));
      setToBalance(await getBalance(toCoin));
    } catch (error) {
      console.log(error);
      setTxStatus(
        error?.error?.data?.message ||
          error?.error?.message ||
          "Transaction Reverted"
      );
    }
  };

  const sellOrio = async () => {
    try {
      const { contract, DECIMALS, ADDRESS } = getTokenData(toCoin);

      let maxSwapAmt = 0;
      let _fromAmount = fromValue;

      const sellQ = ethers.utils.parseUnits(
        fromValue.toString(),
        ORIO.DECIMALS
      );

      if (toCoin !== "BUSD") {
        const OneEth = ethers.utils.parseUnits("1", ORIO.DECIMALS);
        const price = await OrioContract.price();

        // const sellPrice = price.mul(sellQ).div(OneEth);
        const taxPercent = await OrioContract.taxPercent();
        const denominator = await OrioContract.denominator();

        const tax = sellQ.mul(taxPercent).div(denominator);
        const amount_after_tax = sellQ.sub(tax);
        const recAmountInBUSD = price.mul(amount_after_tax).div(OneEth);

        const expOut =
          await swapHelperContract.callStatic.swapTokenBtoS_fixedInput_estimate(
            ADDRESS,
            recAmountInBUSD
          );
        const Dec = expOut.div(100);
        const maxSwapAmt = expOut.sub(Dec);

        _fromAmount = ethers.utils.formatUnits(maxSwapAmt, DECIMALS);
      }

      const _allowance = await contract.allowance(account, ORIO.ADDRESS);
      const allowance = ethers.utils.formatUnits(
        _allowance.toString(),
        DECIMALS
      );

      setTxStatus("checking allowance");
      if (Number(allowance.toString()) < Number(_fromAmount)) {
        setTxStatus("Setting Allowance...");
        const atx = await contract.approve(
          ORIO.ADDRESS,
          ethers.utils.parseUnits("1000000", DECIMALS).toString()
        );
        setTxStatus("Processig Allowance...");
        await atx.wait();
        setTxStatus("Allowance Processed...");
      }

      setTxStatus("Confirm Transaction on Wallet");

      const tx = await OrioContract.sell(sellQ, ADDRESS, maxSwapAmt);

      setTxStatus("Waiting For Confirmation");

      await tx.wait();

      resetState();

      setTxStatus("Transaction Completed");

      setFromBalance(await getBalance(fromCoin));
      setToBalance(await getBalance(toCoin));
    } catch (error) {
      setTxStatus(
        error?.error?.data?.message ||
          error?.error?.message ||
          "Transaction Reverted"
      );
    }
  };

  const transfer = async () => {
    if (OrioContract && txToAc && txAmt) {
      setTxStatus("Confirm Transaction on Wallet");

      const _txAmot = ethers.utils
        .parseUnits(txAmt.toString(), ORIO.DECUMALS)
        .toString();

      const tx = await OrioContract.transfer(txToAc, _txAmot, {
        gasLimit: 200000,
      });

      setTxStatus("Waiting For Confirmation");

      await tx.wait();

      resetState();

      setTxStatus("Transaction Completed");

      setFromBalance(await getBalance(fromCoin));
    }
  };

  useEffect(() => {
    (async function () {
      if (OrioContract) {
        const _price = (await OrioContract.price())?.toString();
        const price = ethers.utils.formatUnits(_price, ORIO.DECIMALS);

        setOrioPrice(Number(price)?.toFixed(4));

        setFromBalance(await getBalance(fromCoin));
      }
    })();
  }, [OrioContract]);

  useEffect(() => {
    if (active && library) {
      setBUSDContract(getContract(BUSD.ADDRESS, BUSD.ABI));
      setUSDPContract(getContract(USDP.ADDRESS, USDP.ABI));
      setUSDCContract(getContract(USDC.ADDRESS, USDC.ABI));
      setDAIContract(getContract(DAI.ADDRESS, DAI.ABI));
      setUSDTContract(getContract(USDT.ADDRESS, USDT.ABI));
      setOrioContract(getContract(ORIO.ADDRESS, ORIO.ABI));
      setswapHelperContract(getContract(SWAPHELPER.ADDRESS, SWAPHELPER.ABI));
    }
  }, [active, library]);

  useEffect(() => {
    (async () => {
      if (toCoin && account) {
        setToBalance(await getBalance(toCoin));
      }
    })();
  }, [toCoin, account, OrioContract]);

  return (
    <>
      <section className="swapsection hwallete-sect pt-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={9} className="text-center">
              <h1 className="text-pink mb-4 display-6 fw-500 text-capitalize">
                Note: Dapp Under Development
              </h1>
              <h2 className="text-white mb-4 display-6 fw-600 text-capitalize">
                <span className="text-purple me-2">Buy Orio</span>
                with Crypto, Fiat, Credit Cards Or Directly Bank Transfer
              </h2>
              <div>
                <Button variant="primary" className="main-btn px-4 fs-18">
                  Coming Soon
                </Button>{" "}
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center py-5">
            <Col md={6} className="p-4">
              <div className="cont-walletbox">
                <div className="wallet-tab">
                  <WalletConnect
                    variant="primary"
                    className="wall-conect-btn"
                  />{" "}
                  <Tabs
                    defaultActiveKey="buy"
                    id="uncontrolled-tab-example"
                    className="position-relative"
                    onSelect={(e) => resetState()}
                  >
                    <Tab eventKey="buy" title="Buy">
                      <div className="p-1 p-sm-4">
                        <Form>
                          <Form.Label className="text-white text-end">
                            Bal : {fromBalance}{" "}
                          </Form.Label>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="0.0000"
                              aria-label="0.0000"
                              aria-describedby="basic-addon2"
                              variant="primary"
                              size="lg"
                              value={fromValue}
                              onChange={(e) => {
                                setFromValue(e.target.value);
                                setToValue(getBuyOrioToToken(e.target.value));
                              }}
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              className="purple-btn text-white px-2 px-md-5"
                            >
                              ORIO
                            </InputGroup.Text>
                          </InputGroup>
                          <div className="text-center">
                            <BsArrowDownUp size={20} className="text-white" />
                          </div>
                          <Form.Label className="text-white text-end">
                            Bal : {toBalance}{" "}
                          </Form.Label>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="0.0000"
                              aria-label="0.0000"
                              size="lg"
                              value={toValue}
                              onChange={(e) => {
                                setToValue(e.target.value);
                                setFromValue(getBuyTokenToOrio(e.target.value));
                              }}
                            />
                            <DropdownButton
                              variant="primary"
                              title={toCoin}
                              id="input-group-dropdown-1"
                              className="purple-btn px-3"
                              onSelect={(k, e) => setToCoin(e.target.innerHTML)}
                            >
                              {_map(items, (key) => (
                                <Dropdown.Item
                                  key={key}
                                  active={key === toCoin}
                                >
                                  {key}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </InputGroup>
                          <Button
                            variant="primary"
                            size="lg"
                            className="w-100 mb-4 main-btn"
                            disabled={!active || !fromValue}
                            onClick={() => buyOrio()}
                          >
                            {active ? "Buy" : "You Are Off Chain"}
                          </Button>
                          {!active && (
                            <Button
                              variant="outline-danger"
                              size="lg"
                              className="w-100 mb-4 text-white text-capitalize connectbtn"
                              disabled
                            >
                              connect your wallet to Buy ORIO
                            </Button>
                          )}
                          <Form.Label className="text-white text-center">
                            {txStatus}
                          </Form.Label>

                          {/* <Form.Label className="text-white">
                          
                        </Form.Label> */}
                          {/* <InputGroup className="mb-0">
                          <Form.Control
                            placeholder="Buy ORIO For Refferal Code"
                            aria-label="Buy ORIO For Refferal Code"
                            size="lg"
                          />
                          <Button variant="primary px-5" className="purple-btn">
                            Copy
                          </Button>
                        </InputGroup> */}
                        </Form>
                      </div>
                    </Tab>

                    <Tab eventKey="sell" title="Sell">
                      <div className="p-1 p-sm-4">
                        <Form>
                          <Form.Label className="text-white text-end">
                            Bal : {fromBalance}{" "}
                          </Form.Label>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="0.0000"
                              aria-label="0.0000"
                              aria-describedby="basic-addon2"
                              variant="primary"
                              size="lg"
                              value={fromValue}
                              onChange={(e) => {
                                setFromValue(e.target.value);
                                setToValue(getSellOrioToToken(e.target.value));
                              }}
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              className="purple-btn text-white px-2 px-md-5"
                            >
                              ORIO
                            </InputGroup.Text>
                          </InputGroup>
                          <div className="text-center">
                            <BsArrowDownUp size={20} className="text-white" />
                          </div>
                          <Form.Label className="text-white text-end">
                            Bal : {toBalance}{" "}
                          </Form.Label>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="0.0000"
                              aria-label="0.0000"
                              size="lg"
                              value={toValue}
                              onChange={(e) => {
                                setToValue(e.target.value);
                                setFromValue(
                                  getSellTokenToOrio(e.target.value)
                                );
                              }}
                            />
                            <DropdownButton
                              variant="primary"
                              title={toCoin}
                              id="input-group-dropdown-1"
                              className="purple-btn"
                              onSelect={(k, e) => setToCoin(e.target.innerHTML)}
                            >
                              {_map(items, (key) => (
                                <Dropdown.Item
                                  key={key}
                                  active={key === toCoin}
                                >
                                  {key}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </InputGroup>
                          <Button
                            variant="primary"
                            size="lg"
                            className="w-100 mb-4 main-btn"
                            disabled={!active || !fromValue}
                            onClick={() => sellOrio()}
                          >
                            {active ? "Sell" : "You Are Off Chain"}
                          </Button>
                          {!active && (
                            <Button
                              variant="outline-danger"
                              size="lg"
                              className="w-100 mb-4 text-white text-capitalize connectbtn"
                              disabled
                            >
                              connect your wallet to Sell ORIO
                            </Button>
                          )}
                          <Form.Label className="text-white text-center">
                            {txStatus}
                          </Form.Label>
                        </Form>
                      </div>
                    </Tab>

                    <Tab eventKey="Transfer" title="transfer">
                      <div className="p-1 p-sm-4">
                        <Form>
                          <Form.Label className="text-white">Form</Form.Label>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="Your Wallet Address"
                              aria-label="Your Wallet Address"
                              aria-describedby="basic-addon2"
                              variant="primary"
                              size="lg"
                              value={account}
                              disabled={true}
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              className="purple-btn text-white px-3 px-md-5"
                            >
                              Address
                            </InputGroup.Text>
                          </InputGroup>
                          <div className="d-flex justify-content-between">
                            <Form.Label className="text-white">
                              Amount
                            </Form.Label>
                            <Form.Label className="text-white">
                              Bal : {fromBalance}
                            </Form.Label>
                          </div>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="0.0000"
                              aria-label="0.0000"
                              aria-describedby="basic-addon2"
                              variant="primary"
                              size="lg"
                              value={txAmt}
                              onChange={(e) => setTxAmt(e.target.value)}
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              className="purple-btn text-white px-3 px-md-5"
                            >
                              ORIO
                            </InputGroup.Text>
                          </InputGroup>
                          <div className="text-center">
                            <BsArrowDownUp size={20} className="text-white" />
                          </div>
                          {/* <Form.Label className="text-white">To</Form.Label> */}
                          <Form.Label className="text-white">To</Form.Label>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="Receiver's Wallet Address"
                              aria-label="Receiver's Wallet Address"
                              aria-describedby="basic-addon2"
                              variant="primary"
                              size="lg"
                              value={txToAc}
                              onChange={(e) => setTxToAc(e.target.value)}
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              className="purple-btn text-white px-3 px-md-5"
                            >
                              Address
                            </InputGroup.Text>
                          </InputGroup>
                          <Button
                            variant="primary"
                            size="lg"
                            className="w-100 mb-4 main-btn"
                            onClick={() => transfer()}
                            disabled={
                              (OrioContract && txToAc && txAmt) === false
                            }
                          >
                            Transfer
                          </Button>
                          <Form.Label className="text-white text-center">
                            {txStatus}
                          </Form.Label>
                        </Form>
                      </div>
                    </Tab>
                    {/* <Tab eventKey="fiat" title="Fiat" disabled>
                    <div className="p-1 p-sm-4">
                      <Form></Form>
                    </div>
                  </Tab> */}
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center pb-5">
            <Col>
              <p className="text-white text-center">
              Add the Orio Contract Address<Nav.Link className="d-inline p-0" target="_blank" href=""> 0x22E0c7f1f1cA75De26fD2380A4D0B8a4eAc23eE4 </Nav.Link> in wallet ( Trustwallet or Metamask)
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <PriceChart />
    </>
  );
};

export default SwapForm;
