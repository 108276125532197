import './App.scss';
import Home from './views/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Whitepaper from './views/Whitepaper';
import Admin from './views/Admin';
import OrioDapp from './views/OrioDapp';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path="/whitepaper" element={<Whitepaper />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/orio-dapp" element={<OrioDapp />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
