import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { networkParams } from "./networks";

const chainId = process.env.REACT_APP_CHAIN_ID;
const network = networkParams[chainId] || {};

export const providers = {
  Injected: new InjectedConnector({}),
  WalletConnect: new WalletConnectConnector({
    // rpcUrl: network?.rpcUrls[0] || "",
    rpc: network.rpcUrls,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
  }),
};
