import React from 'react'
import{ Nav, Navbar, Container, Image, Button } from 'react-bootstrap'
import Logo from "../assets/img/logo.png";

const Header = () => {
  return (
    <header className="">
      <Navbar fixed="top" expand="md" className="py-3">
      <Container>
        <Navbar.Brand href="/" className="text-white bold">
        <Image src={Logo} className="img-fluid"/>
        </Navbar.Brand>
        <div className="d-flex justify-content-end d-md-none d-block">
            {/* <Button className="btn btn-main-outline px-3 fs-11 me-2">Fiat</Button> */}
            <Button className="main-btn px-3 fs-11">Launch DApp</Button>
          </div>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <Navbar.Collapse id="basic-navbar-nav d-md-block d-none">
          <Nav className="ms-auto">
            {/* <Nav.Link href="#link" className="btn btn-main-outline px-4 me-3">Fiat</Nav.Link> */}
            <a href="#SwapSection" className="main-btn px-4 fs-18 me-3">Launch DApp</a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </header>
  )
}

export default Header
