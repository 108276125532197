import React from "react";
import SwapForm from "../component/SwapForm";

const OrioDapp = () => {
  return (
    <>
      <SwapForm />
    </>
  );
};

export default OrioDapp;
