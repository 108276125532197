export const networkParams = {
  1: {
    chainId: "0x1",
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    chainName: "Ethereum Mainnet",
    nativeCurrency: { name: "Ethereum Mainnet", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://etherscan.io"],
  },
  5: {
    chainId: "0x5",
    rpcUrls: ["https://goerli.infura.io/v3/"],
    chainName: "Goerli test network",
    nativeCurrency: { name: "GoerliETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  },
};
